<template>
  <div id="landing">
    <div class="home-cnt">
      <b-container>
        <div class="home-content">
          <div class="home-title">
            <h1>Allegiant Stadium</h1>
            <h1 class="subtitle">Impactful Events Happen Here</h1>

            <!-- Welcome To Allegiant Stadium Where Events Are Our -->
            <!-- <span>Field</span> Of Expertise -->
          </div>
          <div class="home-divider"></div>
          <div class="home-text">
            <p>
              Home of the Las Vegas Raiders and world-class events, Allegiant
              Stadium has redefined the private event landscape in Las Vegas.
            </p>
            <br />
            <p>
              The iconic, state of the art venue offers limitless opportunities
              ranging from intimate receptions to full scale private concerts
              and keynotes.
            </p>
            <br />
            <p>
              Featuring cutting edge technology, unique experiences, and
              immaculate finishes in each event space, Allegiant Stadium creates
              an unmatched atmosphere for any event.
            </p>
            <!-- Home of the Las Vegas Raiders and world-class events, Allegiant Stadium
			<span>has redefined the private event landscape in Las Vegas.  </span> The iconic, state
			<span>of the art venue offers limitless opportunities ranging from intimate receptions to </span>full
			<span>scale private concerts and keynotes. Featuring cutting edge technology,  </span>unique
			<span>experiences, and immaculate finishes in each event space, Allegiant Stadium</span> creates
			<span>an unmatched atmosphere for any event</span>. -->
            <!-- Home of the Las Vegas Raiders and UNLV Rebels, Allegiant Stadium is
            <span>re-defining the event possibilities in Las Vegas.</span> This highly anticipated
            <span>venue features a variety of multi-use event spaces</span> equipped with cutting-edge
            <span>technology, state of the art features, and immaculate finishes</span>. -->
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Landing",
};
</script>

<style lang="scss" scoped>
#landing {
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/images/Landing/bg-landing.jpg") scroll no-repeat
    center center;
  background-size: cover;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.79) 25%,
      rgba(0, 0, 0, 0.02) 66%
    );
    @media (max-width: $sm) {
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.79) 70%,
        rgba(0, 0, 0, 0.02) 90%
      );
    }
  }
  .home-cnt {
    position: relative;
    display: flex;
    top: 130px;
    justify-content: center;
    width: 100%;
    .home-content {
      .home-title {
        max-width: 850px;
        margin: 0 auto;
        font-size: 35px;
        font-family: "ArtegraSans-Bold";
        // line-height: 37px;
        line-height: 50px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        @media (max-width: $sm) {
          font-size: 25px;
          line-height: 30px;
        }
        @media (min-width: 2000px) {
          font-size: 50px;
          line-height: 50px;
        }
        .subtitle {
          margin-top: 40px;
        }
        span {
          // filter: drop-shadow(0 0 9px rgba(255,255,255,0.90));
          text-shadow: 0 0 9px rgba(255, 255, 255, 0.9);
        }
      }
      .home-divider {
        position: relative;
        max-width: 680px;
        height: 2px;
        margin: 20px auto;
        background-color: #ffffff;
        box-shadow: 0 0 9px 2px #ffffff;
      }
      .home-text {
        max-width: 690px;
        margin: 0 auto;
        font-size: 16px;
        font-family: "ArtegraSans-Regular";
        line-height: 27px;
        letter-spacing: 1.07px;
        text-align: center;
        color: #ffffff;
        @media (max-width: $sm) {
          font-size: 14px;
          line-height: 22px;
        }
        @media (min-width: 2000px) {
          font-size: 26px;
          line-height: 34px;
          max-width: none;
          margin-top: 30px;
        }
        span {
          white-space: nowrap;
          @media (max-width: $sm) {
            white-space: normal;
          }
        }
      }
    }
  }
}
</style>
